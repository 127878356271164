import React from 'react';

// Components
import Layout from 'components/Layout';
import Post from 'components/Post';

// Utilities
import { propTypes } from 'shapes/templates/blog';
import { graphql } from 'gatsby';

const BlogTemplate = ({ data: { markdownRemark } }) => (
    <Layout>
        <Post post={markdownRemark} />
    </Layout>
);

BlogTemplate.propTypes = propTypes;

export default BlogTemplate;

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            timeToRead
            wordCount {
                words
            }
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                tags
            }
        }
    }
`;
